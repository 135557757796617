export const EVENTS = {
    KILLSHEET_SPECIES_FILTER: 'KillSheet Species Filter',
    FINAL_KILLSHEET_DETAIL: 'Final KillSheet Detail',
    FINAL_KILLSHEET_VIEW_PDF: 'Final KillSheet View PDF',
    FINAL_KILLSHEET_DOWNLOAD_PDF: 'Final KillSheet Download PDF',
    FINAL_KILLSHEET_DOWNLOAD_EXCEL: 'Final KillSheet Download XLSX',
    KILLSHEET_GRADE_BREAKDOWN: 'KillSheet Grade Breakdown',
    LOGOUT: 'Logout',
    FORCE_LOGOUT: 'Force Logout',
    INTERM_KILLSHEET_DETAIL: 'Interim KillSheet Detail',
    FARM_SWITCHER: 'Farm Switcher',
    MY_SHARES_PATRONAGE_FIND_OUT_MORE: 'My Shares Patronage Find Out More',
    MY_SHARES_TOOL_TIP: 'My Shares Tool Tip',
    LOGIN: 'Login',
    PAYMENT_DATE_PICKER: 'Payment Date Picker',
    DASHBOARD_ANALYTICS: 'Dashboard Analytics',
    DASHBOARD_KILLSHEET: 'Dashboard KillSheet',
    DASHBOARD_SCHEDULE: 'Dashboard Schedule',
    DASHBOARD_BOOKING: 'Dashboard Booking',
    DASHBOARD_FORECAST: 'Dashboard Forecast',
    DASHBOARD_SHARE_PRICE: 'Dashboard Share Price',
    DASHBOARD_FARM_ASSURANCE: 'Dashboard Farm Assurance',
    DASHBOARD_EVENTS_STREAM: 'Dashboard Events Stream',
    DASHBOARD_NEWS_STREAM: 'Dashboard News Stream',
    DASHBOARD_NEWS_LOAD_MORE: 'Dashboard News Load More',
    WHATS_NEW_CLOSE: 'What\'s New Close',
    WHATS_NEW_ITEMS_VIEWED: 'What\'s New Items Viewed',
    APPLE_STORE_CLICK: "Mobile Application Required Dialog Apple Store Click",
    ANDROID_STORE_CLICK: "Mobile Application Required Dialog Android Store Click",
    MOBILE_APP_DIALOG_EMAIL_CLICK: "Mobile Application Required Dialog Support Link Click",
    MOBILE_APP_DIALOG_OPEN: "Mobile Application Required Dialog Open",
    IOS_MOBILE_ONLY_OPEN: "IOS Mobile application required dialog open",
    MOBILE_APP_DIALOG_CLOSE: "Mobile Application Required Dialog Close Click",
    BANNNER_LINK_CLICK: 'Banner Link Click',
    BANNNER_CLOSE_CLICK: 'Banner Close',
    FINAL_KILLSHEET_VIEW_CARCASE_REPORT: 'Final KillSheet View Carcase Report',
    INTERIM_KILLSHEET_VIEW_CARCASE_REPORT: 'Interim KillSheet View Carcase Report',
    VIEW_KILLSHEET_PDF_DETAILS: 'View KillSheet PDF Details',
    DOWNLOAD_KILLSHEET_PDF_DETAILS: 'Download KillSheet PDF Details',
    DOWNLOAD_KILL_DATA_XLSX_DETAILS: 'Download Kill Data XLSX Details',
    KILLSHEET_DETAIL_COLLAPSED: 'KillSheet Detail Collapsed',
    KILLSHEET_DETAIL_EXPANDED: 'KillSheet Detail Expanded',
    TEAM_ACCESS_OPEN: 'Team Access Only Dialog Open',
    COMPLETE_REG_START: 'Complete Registration Start',
    COMPLETE_REG_AUTHENTICATED: 'Complete Registration Authenticated',
    COMPLETE_REG_NOTAUTHENTICATED: 'Complete Registration Not Authenticated',
    COMPLETE_REG_PATH_INCOMPLETE: 'Complete Registration Path Incomplete',
    COMPLETE_REG_CALL_B2C: 'Complete Registration Calling B2C Password',
    COMPLETE_REG_CALLED_B2C_SUCCESSFUL: 'Complete Registration Called B2C Successfully',
    COMPLETE_REG_CALLED_B2C_FAILED: 'Complete Registration Called B2C Failed',
    VIEW_LEGAL_TERMS: 'View Legal Terms Page'

};

export const CATEGORIES = {
    USER: 'User'
};

export enum TelemetryEventType {
    General,
    Page
}

export enum TelemetryExceptionType {
    ApiError,
    AdError,
}

export enum TelemetryPageEventType {
    PageStart,
    PageStop,
}