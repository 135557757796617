import { Paper, Tab, Tabs, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';
import SwipeableViews from 'react-swipeable-views';
import * as DASHBOARD from '../App/Constants/Dashboard';
import * as EVENTS from '../App/Constants/Events';
import * as TELEMETRY from '../App/Constants/Telemetry';
import * as TILE_TITLES from '../App/Constants/TileTitles';
import * as TRANSITION from '../App/Constants/Transition';
import DashboardStore from '../App/Stores/DashboardStore';
import AuthorizationStore from '../App/Stores/AuthorizationStore';
import ProgressIndicator from '../Progress/ProgressIndicator';
import EventsStream from './EventsStream';
import NewsStream from './NewsStream';

const styles = (theme: any) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 500,
  }
});

interface Props {
  authorizationStore: AuthorizationStore;
  dashboardStore: DashboardStore;
  theme: any;
}

interface State {
  isDisplayLoadingAnimation: boolean;
  value: number;
}

class StreamTile extends React.Component<Props, State> {

  private timeout: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      value: 0,
      isDisplayLoadingAnimation: false
    };
  }

  componentDidMount = () => {
    const element = document.getElementById('c-dashboard__tile-stream-tabs-view');
    element!.addEventListener(EVENTS.MOUSE_SCROLL, this.trackScrolling.bind(this));
  }

  getStreamData = (tabIndex: number) => {
    const { dashboardStore } = this.props;
    switch (tabIndex) {
      case DASHBOARD.TAB_NEWS:
        window.telemetry.logEvent(TELEMETRY.EVENTS.DASHBOARD_NEWS_STREAM);
        dashboardStore!.getNewsStreamData(true);
        break;
      case DASHBOARD.TAB_EVENTS:
        window.telemetry.logEvent(TELEMETRY.EVENTS.DASHBOARD_EVENTS_STREAM);
        dashboardStore!.getEventsStreamData(true);
        break;
      default:
        break;
    }
  }

  handleChange = (event: any, value: any) => {
    this.setState({ value });
    this.getStreamData(value);
  }

  handleChangeIndex = (index: number) => {
    this.setState({ value: index });
    this.getStreamData(index);
  }

  trackScrolling = (event: any) => {
    let index = this.state.value;
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      const _node: any = event.target;
      const { dashboardStore } = this.props;
      const scrollTop = Math.ceil(_node.scrollTop);
      const isBottomOfDiv =
        _node.scrollHeight - scrollTop <= _node.clientHeight;
      const _element = document.getElementById('c-dashboard__tile-stream-tabs-view');

      switch (index) {
        case DASHBOARD.TAB_NEWS:
          if (isBottomOfDiv && !dashboardStore.newsArticlesAllRetrieved && _node.scrollTop > 0) {
            this.setState({ isDisplayLoadingAnimation: true });
            window.telemetry.logEvent(TELEMETRY.EVENTS.DASHBOARD_NEWS_LOAD_MORE);
            dashboardStore!.getNewsStreamData()!.then(() => {
              this.setState({ isDisplayLoadingAnimation: false });
              _element!.scrollTop = scrollTop;
            });
          }
          break;
        case DASHBOARD.TAB_EVENTS:
          if (isBottomOfDiv && !dashboardStore.eventsArticlesAllRetrieved && _node.scrollTop > 0) {
            this.setState({ isDisplayLoadingAnimation: true });
            window.telemetry.logEvent(TELEMETRY.EVENTS.DASHBOARD_EVENTS_STREAM);
            dashboardStore!.getEventsStreamData()!.then(() => {
              this.setState({ isDisplayLoadingAnimation: false });
              _element!.scrollTop = scrollTop;
            });
          }
          break;
        default:
          break;
      }
    }, TRANSITION.TIMELINE_SCROLL_DELAY);
  }

  render() {
    const { theme, dashboardStore, authorizationStore } = this.props;
    return (
      <Paper className="c-dashboard__tile">
        <div className="c-dashboard__tile-stream">
          <Tabs
            value={this.state.value}
            onChange={this.handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            className="c-dashboard__tile-stream-tabs"
          >
            <Tab label="News" className="c-dashboard__tile-stream-tab" />
            <Tab label="Events" className="c-dashboard__tile-stream-tab" />
          </Tabs>
          <SwipeableViews
            id="c-dashboard__tile-stream-tabs-view"
            disabled={true}
            onChangeIndex={this.handleChangeIndex}
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={this.state.value}
            className={classNames('p-dashboard__scroll-container', 'c-dashboard__tile-stream-tabs-view')}
          >
            <NewsStream dashboardStore={dashboardStore} authorizationStore={authorizationStore} title={TILE_TITLES.NEWS} loadData={dashboardStore.getNewsStreamData} />
            <EventsStream dashboardStore={dashboardStore} authorizationStore={authorizationStore} title={TILE_TITLES.EVENTS} loadData={dashboardStore.getEventsStreamData} />
          </SwipeableViews>
          <ProgressIndicator
            isDisplayed={this.state.isDisplayLoadingAnimation}
            id="c-progress__timeline-stream"
          />
        </div>
      </Paper>
    );
  }
}

export default withStyles(styles, { withTheme: true })(StreamTile);