import _ from 'lodash';
import { observable, action } from 'mobx';
import { TileStatus } from '../Constants/TileStatus';
import BookingService from '../Services/BookingService';
import ScheduleService from '../Services/ScheduleService';
import Booking from '../Models/Booking';
import StockHelper from '../Helpers/StockHelper';
import StorageHelper from '../Helpers/StorageHelper';
import * as DEFAULT_DATA from '../../App/Constants/DefaultData';
import * as STORAGE from '../Constants/Storage';
import * as TILE_TITLES from '../Constants/TileTitles';
import * as DASHBOARD from '../Constants/Dashboard';
import StreamArticle from '../Models/StreamArticle';
import StreamService from '../Services/StreamService';
import FarmService from '../Services/FarmService';
import NzfapCertificate from '../Models/NzfapCertificate';
import ForecastService from '../Services/ForecastService';
import Forecast from '../Models/Forecast';
import { NO_CONTENT } from '../Constants/HttpResponseCode';
import KillSheetService from '../Services/KillSheetService';
import ShareholderService from '../Services/ShareholderService';

interface Props {
  dashboardStore?: DashboardStore;
  component?: any;
  title?: string;
}

export default class DashboardStore {

  @observable public killsheetCount: number;
  @observable public killsheetSeasonStartDate?: Date;
  @observable public killsheetStatus: TileStatus | undefined;

  @observable public scheduleValidFromDatetime?: Date;
  @observable public scheduleStatus: TileStatus | undefined;
  @observable public scheduleCompleted: boolean;

  @observable public bookingTileIcon?: string;
  @observable public bookingTileQuantity?: number;
  @observable public bookingTileDate?: Date;
  @observable public bookingProgramme?: string;
  @observable public bookingsCompleted: boolean;
  @observable public bookingStatus: TileStatus | undefined;

  @observable public forecastTileIcon?: string;
  @observable public forecastTileQuantity?: number;
  @observable public forecastTileDate?: Date;
  @observable public forecastProgramme?: string;
  @observable public forecastsCompleted: boolean;
  @observable public forecastsStatus: TileStatus | undefined;

  @observable public farmAssuranceCertificates: NzfapCertificate[] = [];
  @observable public farmAssuranceCompleted: boolean;
  @observable public farmAssuranceStatus: TileStatus | undefined;

  @observable public newsArticlesCurrentPage: number;
  @observable public newsArticles: Array<StreamArticle>;
  @observable public newsArticlesAllRetrieved: boolean;
  @observable public newsArticlesStatus: TileStatus | undefined;

  @observable public eventsArticlesCurrentPage: number;
  @observable public eventsArticles: Array<StreamArticle>;
  @observable public eventsArticlesAllRetrieved: boolean;
  @observable public eventsArticlesStatus: TileStatus | undefined;

  @observable public shareTileSharePrice: number;
  @observable public shareTilePriceDifference: number;
  @observable public shareTileUpdateDate?: Date;
  @observable public shareTileTrend: number;
  @observable public shareTileStatus: TileStatus | undefined;
  
  @observable public tabIndex: number;

  private _killsheetService: KillSheetService = new KillSheetService();
  private _bookingService: BookingService = new BookingService();
  private _scheduleService: ScheduleService = new ScheduleService();
  private _forecastService: ForecastService = new ForecastService();
  private _streamService: StreamService = new StreamService();
  private _farmService: FarmService = new FarmService();
  private _stockHelper: StockHelper = new StockHelper();
  private _storageHelper: StorageHelper = new StorageHelper();
  private _shareholderService: ShareholderService = new ShareholderService();

  constructor() {
    this.newsArticlesCurrentPage = DASHBOARD.DEFAULT_PAGE;
    this.newsArticles = new Array<StreamArticle>();
    this.newsArticlesAllRetrieved = false;
    this.eventsArticlesCurrentPage = DASHBOARD.DEFAULT_PAGE;
    this.eventsArticles = new Array<StreamArticle>();
    this.eventsArticlesAllRetrieved = false;
    this.tabIndex = -1;
  }

  @action
  refreshDashboard = (island: string, initialStream?: boolean) => {
    this.getKillSheetTileData();
    this.getForecastTileData();
    this.getBookingTileData();
    this.getScheduleTileData(island);
    this.getFarmAssuranceTileData();
    this.getSharePriceTileData();
    this.getAnalyticsTileData();
    this.getNewsStreamData(initialStream);
    this.getEventsStreamData(initialStream);
  }

  @action
  setTileStatus = (title: string, tileStatus: TileStatus | undefined): void => {

    switch (title) {
      case TILE_TITLES.KILLSHEET:
        this.killsheetStatus = tileStatus;
        break;
      case TILE_TITLES.SCHEDULE:
        this.scheduleStatus = tileStatus;
        break;
      case TILE_TITLES.BOOKING:
        this.bookingStatus = tileStatus;
        break;
      case TILE_TITLES.FORECAST:
        this.forecastsStatus = tileStatus;
        break;
      case TILE_TITLES.NEWS:
        this.newsArticlesStatus = tileStatus;
        break;
      case TILE_TITLES.EVENTS:
        this.eventsArticlesStatus = tileStatus;
        break;
      case TILE_TITLES.SHARE_PRICE:
        this.shareTileStatus = tileStatus;
        break;
      case TILE_TITLES.FARM_ASSURED:
        this.farmAssuranceStatus = tileStatus;
        break;
      default:
        break;
    }
  }

  @action
  getTileStatus = (title: string): TileStatus | undefined => {
    switch (title) {
      case TILE_TITLES.KILLSHEET:
        return this.killsheetStatus;
      case TILE_TITLES.SCHEDULE:
        return this.scheduleStatus;
      case TILE_TITLES.BOOKING:
        return this.bookingStatus;
      case TILE_TITLES.FORECAST:
        return this.forecastsStatus;
      case TILE_TITLES.NEWS:
        return this.newsArticlesStatus;
      case TILE_TITLES.EVENTS:
        return this.eventsArticlesStatus;
      case TILE_TITLES.FARM_ASSURED:
        return this.farmAssuranceStatus;
      case TILE_TITLES.BOOKING_FORECAST_LOADING:
      case TILE_TITLES.FARM_ASSURED_LOADING:
        return TileStatus.LOADING;
      case TILE_TITLES.SHARE_PRICE:
        return this.shareTileStatus;
      default:
        if (_.startsWith(title, TILE_TITLES.FARM_ASSURED)) {
          return this.farmAssuranceStatus;
        } else { return TileStatus.COMING_SOON; }
    }
  }

  @action
  getBookingTileData = async () => {
    this.bookingsCompleted = false;
    let _farmId = this._storageHelper.getKeyValue(STORAGE.SELECTED_FARM_ID)!; // Assuming will always have a value.
    return this.getBookings(_farmId);
  }

  @action
  getBookings = async (farmId: string) => {
    this.bookingTileIcon = DEFAULT_DATA.UNDEFINED;
    this.bookingTileQuantity = DEFAULT_DATA.UNDEFINED;
    this.bookingTileDate = DEFAULT_DATA.UNDEFINED;
    this.bookingProgramme = DEFAULT_DATA.UNDEFINED;
    try {
      this.setTileStatus(TILE_TITLES.BOOKING, TileStatus.LOADING);
      const response = await this._bookingService.getBookingsDashboard(farmId);
      if (response!.data) {
        let _bookings = response!.data.bookings;
        let _bookingsResult = new Array<Booking>();
        _bookings.map((booking: Booking) => {
          _bookingsResult.push(Object.setPrototypeOf(booking, Booking.prototype));
        });
        const bookings = this._stockHelper.sortBookings(_bookingsResult);
        if (bookings && !_.isEmpty(bookings)) {
          this.bookingTileIcon = bookings[0].species;
          this.bookingTileQuantity = bookings[0].quantity;
          this.bookingTileDate = bookings[0].arrivalDate;
          this.bookingProgramme = _.isEmpty(bookings[0].specialProgramme) ? DEFAULT_DATA.HYPHEN : bookings[0].specialProgramme;
          this.setTileStatus(TILE_TITLES.BOOKING, TileStatus.LOADED);
          this.bookingsCompleted = true;
        } else {
          this.setTileStatus(TILE_TITLES.BOOKING, TileStatus.LOADED_NO_DATA);
          this.bookingsCompleted = true;
        }
      } else {
        this.setTileStatus(TILE_TITLES.BOOKING, TileStatus.LOADED_NO_DATA);
        this.bookingsCompleted = true;
      }
    } catch (e) {
      this.setTileStatus(TILE_TITLES.BOOKING, TileStatus.LOAD_ERROR);
      this.bookingsCompleted = true;
    }
  }

  @action
  getKillSheetTileData = async () => {
    let _farmId = this._storageHelper.getKeyValue(STORAGE.SELECTED_FARM_ID)!; // Assuming will always have a value.
    this.getKillSheet(_farmId);
  }

  @action
  getKillSheet = async (farmId: string) => {
    this.killsheetCount = DEFAULT_DATA.NUMBER;
    this.killsheetSeasonStartDate = DEFAULT_DATA.UNDEFINED;
    try {
      this.setTileStatus(TILE_TITLES.KILLSHEET, TileStatus.LOADING);
      const response = await this._killsheetService.getKillSheetForDashBoard(farmId);

      if (response && response.status === NO_CONTENT) {
        this.setTileStatus(TILE_TITLES.KILLSHEET, TileStatus.LOADED_NO_DATA);
      }

      if (response!.data) {
        let _killSheet = response!.data;
        if (_killSheet && !_.isEmpty(_killSheet)) {
          this.killsheetSeasonStartDate = _killSheet.seasonStartDate;
          this.killsheetCount = _killSheet.killsheetCount;
          this.setTileStatus(TILE_TITLES.KILLSHEET, TileStatus.LOADED);
        } else {
          this.setTileStatus(TILE_TITLES.KILLSHEET, TileStatus.LOADED_NO_DATA);
        }
      } else {
        this.setTileStatus(TILE_TITLES.KILLSHEET, TileStatus.LOADED_NO_DATA);
      }
    } catch (error) {
      this.setTileStatus(TILE_TITLES.KILLSHEET, TileStatus.LOAD_ERROR);
    }
  }

  getForecastTileData = async () => {
    this.forecastsCompleted = false;
    let _farmId = this._storageHelper.getKeyValue(STORAGE.SELECTED_FARM_ID)!; // Assuming will always have a value.
    return this.getForecasts(_farmId);
  }

  @action
  getForecasts = async (farmId: string) => {
    this.forecastTileIcon = DEFAULT_DATA.UNDEFINED;
    this.forecastTileQuantity = DEFAULT_DATA.UNDEFINED;
    this.forecastTileDate = DEFAULT_DATA.UNDEFINED;
    this.forecastProgramme = DEFAULT_DATA.UNDEFINED;
    try {
      this.setTileStatus(TILE_TITLES.FORECAST, TileStatus.LOADING);
      const response = await this._forecastService.getForecastsDashboard(farmId);
      if (response!.data) {
        let _forecasts = response!.data.forecasts;
        let _forecastsResult = new Array<Forecast>();
        _forecasts.map((forecast: Forecast) => {
          _forecastsResult.push(Object.setPrototypeOf(forecast, Forecast.prototype));
        });
        const forecasts = this._stockHelper.sortForecasts(_forecastsResult);
        if (forecasts && !_.isEmpty(forecasts)) {
          this.forecastTileIcon = forecasts[0].species;
          this.forecastTileQuantity = forecasts[0].quantity;
          this.forecastTileDate = forecasts[0].weekStartDate;
          this.forecastProgramme = _.isEmpty(forecasts[0].specialProgramme) ? DEFAULT_DATA.HYPHEN : forecasts[0].specialProgramme;
          this.setTileStatus(TILE_TITLES.FORECAST, TileStatus.LOADED);
          this.forecastsCompleted = true;
        } else {
          this.setTileStatus(TILE_TITLES.FORECAST, TileStatus.LOADED_NO_DATA);
          this.forecastsCompleted = true;
        }
      } else {
        this.setTileStatus(TILE_TITLES.FORECAST, TileStatus.LOADED_NO_DATA);
        this.forecastsCompleted = true;
      }
    } catch (error) {
      this.setTileStatus(TILE_TITLES.FORECAST, TileStatus.LOAD_ERROR);
      this.forecastProgramme = DEFAULT_DATA.HYPHEN;
      this.forecastsCompleted = true;
    }
  }

  @action
  getScheduleTileData = async (island: string) => {
    this.scheduleCompleted = false;
    return this.getSchedule(island);
  }

  @action
  getSchedule = async (island: string) => {
    this.scheduleValidFromDatetime = DEFAULT_DATA.UNDEFINED;
    try {
      this.setTileStatus(TILE_TITLES.SCHEDULE, TileStatus.LOADING);
      const response = await this._scheduleService.getScheduleDashboard(encodeURI(island));
      if (response && response.status === NO_CONTENT) {
        this.setTileStatus(TILE_TITLES.SCHEDULE, TileStatus.LOADED_NO_DATA);
      } else if (response!.data) {
        this.scheduleValidFromDatetime = response!.data.validFromDatetime;
        this.setTileStatus(TILE_TITLES.SCHEDULE, TileStatus.LOADED);
      } else {
        this.setTileStatus(TILE_TITLES.SCHEDULE, TileStatus.LOADED_NO_DATA);
      }
    } catch (e) {
      this.setTileStatus(TILE_TITLES.SCHEDULE, TileStatus.LOAD_ERROR);
    } finally {
      this.scheduleCompleted = true;
    }
  }

  @action
  getFarmAssuranceTileData = async () => {
    this.farmAssuranceCertificates = [];
    this.farmAssuranceCompleted = false;
    let _farmId = this._storageHelper.getKeyValue(STORAGE.SELECTED_FARM_ID)!;
    this.getFarmAssuranceCertificates(_farmId);
  }

  @action
  getFarmAssuranceCertificates = async (farmId: string) => {
    this.setTileStatus(TILE_TITLES.FARM_ASSURED, TileStatus.LOADING);
    return this._farmService
      .getNzfapCertificatesDashboard(farmId)
      .then(response => {
        if (response!.data) {
          this.farmAssuranceCertificates = response!.data.farmAssuranceCertificates;
          this.setTileStatus(TILE_TITLES.FARM_ASSURED, TileStatus.LOADED);
          this.farmAssuranceCompleted = true;
        } else {
          this.setTileStatus(TILE_TITLES.FARM_ASSURED, TileStatus.LOADED_NO_DATA);
          this.farmAssuranceCompleted = true;
        }
      })
      .catch(() => {
        this.setTileStatus(TILE_TITLES.FARM_ASSURED, TileStatus.LOAD_ERROR);
        this.farmAssuranceCompleted = true;
      });
  }

  @action
  getSharePriceTileData = async () => {
    this.shareTilePriceDifference = DEFAULT_DATA.NUMBER;
    this.shareTileSharePrice = DEFAULT_DATA.NUMBER;
    this.shareTileTrend = DEFAULT_DATA.NUMBER;
    this.shareTileUpdateDate = DEFAULT_DATA.UNDEFINED;
    try {
      this.setTileStatus(TILE_TITLES.SHARE_PRICE, TileStatus.LOADING);
      const response = await this._shareholderService.getCurrentSharePrice();
      if (response && response!.data) {
        this.shareTilePriceDifference = response!.data.sharePriceDifference;
        this.shareTileSharePrice = response!.data.sharePrice;
        this.shareTileTrend = response!.data.trend;
        this.shareTileUpdateDate = response!.data.updateDate;
        this.setTileStatus(TILE_TITLES.SHARE_PRICE, TileStatus.LOADED);
      } else {
        this.setTileStatus(TILE_TITLES.SHARE_PRICE, TileStatus.LOADED_NO_DATA);
        this.forecastsCompleted = true;
      }
    } catch (error) {
      this.setTileStatus(TILE_TITLES.SHARE_PRICE, TileStatus.LOAD_ERROR);
      this.forecastProgramme = DEFAULT_DATA.HYPHEN;
      this.forecastsCompleted = true;
    }
  }

  @action
  getAnalyticsTileData = async () => { return null; }

  @action
  getNewsStreamData = async (initialStream?: boolean) => {

    if (initialStream) {
      this.newsArticlesCurrentPage = DASHBOARD.DEFAULT_PAGE;
      this.newsArticles = new Array<StreamArticle>();
      this.newsArticlesAllRetrieved = false;
    }

    this.setTileStatus(TILE_TITLES.NEWS, TileStatus.LOADING);
    return this._streamService
      .getNewsStream(++this.newsArticlesCurrentPage, DASHBOARD.STREAM_PAGE_SIZE)
      .then(response => {
        if (response && response.status === NO_CONTENT) {
          this.setTileStatus(TILE_TITLES.NEWS, TileStatus.LOADED_NO_DATA);
        } else if (response!.data && response!.data!.content) {
          this.assignOrAppendNewsArticles(response!.data!.content);
          this.setTileStatus(TILE_TITLES.NEWS, TileStatus.LOADED);
        } else if (this.newsArticlesCurrentPage === DASHBOARD.DEFAULT_PAGE + 1) {
          this.setTileStatus(TILE_TITLES.NEWS, TileStatus.LOADED_NO_DATA);
          this.newsArticlesAllRetrieved = true;
        } else {
          this.setTileStatus(TILE_TITLES.NEWS, TileStatus.LOADED);
          this.newsArticlesAllRetrieved = true;
        }
      })
      .catch(() => {
        this.setTileStatus(TILE_TITLES.NEWS, TileStatus.LOAD_ERROR);
        this.newsArticlesAllRetrieved = true;
        this.newsArticlesCurrentPage = DASHBOARD.DEFAULT_PAGE;
      });
  }

  assignOrAppendNewsArticles = (articles: Array<StreamArticle>) => {
    let _articles = articles;
    if (_articles) {

      _articles.forEach((article, index) => {
        article.id = this.newsArticles.length + index;
        article.summary = _.isEmpty(article.summary) ? DEFAULT_DATA.WHITESPACE : article.summary;
      });

      if (this.newsArticlesCurrentPage && this.newsArticlesCurrentPage > DASHBOARD.DEFAULT_PAGE) {
        this.newsArticles.push(..._articles);
      } else {
        this.newsArticles = _articles;
      }
    }
  }

  @action
  getEventsStreamData = async (initialStream?: boolean) => {

    if (initialStream) {
      this.eventsArticlesCurrentPage = DASHBOARD.DEFAULT_PAGE;
      this.eventsArticles = new Array<StreamArticle>();
      this.eventsArticlesAllRetrieved = false;
    }

    this.setTileStatus(TILE_TITLES.EVENTS, TileStatus.LOADING);
    return this._streamService
      .getEventsStream(++this.eventsArticlesCurrentPage, DASHBOARD.STREAM_PAGE_SIZE)
      .then(response => {
        if (response && response.status === NO_CONTENT) {
          this.setTileStatus(TILE_TITLES.EVENTS, TileStatus.LOADED_NO_DATA);
        } else if (response!.data && response!.data!.content) {
          this.assignOrAppendEventsArticles(response!.data!.content);
          this.setTileStatus(TILE_TITLES.EVENTS, TileStatus.LOADED);
        } else if (this.eventsArticlesCurrentPage === DASHBOARD.DEFAULT_PAGE + 1) {
          this.setTileStatus(TILE_TITLES.EVENTS, TileStatus.LOADED_NO_DATA);
          this.eventsArticlesAllRetrieved = true;
        } else {
          this.setTileStatus(TILE_TITLES.EVENTS, TileStatus.LOADED);
          this.eventsArticlesAllRetrieved = true;
        }
      })
      .catch(() => {
        this.setTileStatus(TILE_TITLES.EVENTS, TileStatus.LOAD_ERROR);
        this.eventsArticlesAllRetrieved = true;
        this.eventsArticlesCurrentPage = DASHBOARD.DEFAULT_PAGE;
      });
  }

  assignOrAppendEventsArticles = (articles: Array<StreamArticle>) => {
    let _articles = articles;
    if (_articles) {

      _articles.forEach((article, index) => {
        article.id = this.eventsArticles.length + index;
        article.summary = _.isEmpty(article.summary) ? DEFAULT_DATA.WHITESPACE : article.summary;
      });

      if (this.eventsArticlesCurrentPage && this.eventsArticlesCurrentPage > DASHBOARD.DEFAULT_PAGE) {
        this.eventsArticles.push(..._articles);
      } else {
        this.eventsArticles = _articles;
      }
    }
  }

}