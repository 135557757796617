export const KILLSHEET = 'KillSheet';
export const BOOKING_FORECAST_LOADING = 'Booking Forecast Loading';
export const FARM_ASSURED_LOADING = 'Farm Assured Loading';
export const BOOKING = 'Booking';
export const SCHEDULE = 'Schedule';
export const FORECAST = 'Forecast';
export const FARM_ASSURED = 'Farm Assurance';
export const WITHDRAWN = 'Withdrawn';
export const ASSESSED = 'Assessed';
export const SHARE_PRICE = 'Share Price';
export const SHARE_PRICE_HISTORY = 'Share Price History';
export const ANALYTICS = 'Analytics';
export const EVENTS = 'Events';
export const NEWS = 'News';